/*
 * @Descripttion:  封装axios
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-24 10:14:46
 */

import axios from 'axios'
import Vue  from 'vue'
// import qs from 'qs'

const err = (error) => {
  if (error.response) {
    let data = error.response.data
    switch (error.response.status) {
      case 403:
        Vue.prototype.$message.error('系统提示: 拒绝访问')
        break
      case 500:
        if(data.message.includes("Token失效")){
          Vue.prototype.$alert('很抱歉，登录已过期，请重新登录','登录已过期',{
            confirmButtonText: '确定',
            callback: action => {
              const url = window.document.location.href;
              const path = window.document.location.pathname;
              Vue.ls.remove("token")
              window.location.href = (url.substring(0, url.indexOf(path))) + "/login"
            }
          })
        }
        break
      case 404:
        Vue.prototype.$message.error('系统提示：很抱歉，资源未找到!')
        break
      case 504:
        Vue.prototype.$message.error('系统提示：网络超时')
        break
      case 401:
        Vue.prototype.$message.error('系统提示：很抱歉，登录已过期，请重新登录')
        break
      default:
        Vue.prototype.$message.error('系统提示：' + data.message)
        break
    }
  } else if (error.message) {
    if (error.message.includes('timeout')) {
      Vue.prototype.$message.error('系统提示：网络超时' )
    } else {
      Vue.prototype.$message.error('系统提示：' + error.message )
    }
  }
  return Promise.reject(error)
};

// 请求头默认配置
axios.defaults.headers['Content-Type'] = 'application/json'

// 超时时间（ms）
axios.defaults.timeout = 2000 * 1000;

// 默认baseURL
axios.defaults.baseURL = ''

const httpGet = (url,params) => {
  return new Promise((resolve, reject) =>{
    axios({
      method:'get',
      url,
      params,
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

const httpPost = (url,data) => {
  return new Promise((resolve, reject) =>{
    axios({
      method:'post',
      url,
      data: data
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

const httpPut = (url, data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url,
      data,
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

const httpDelete = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url,
      params,
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

axios.interceptors.request.use(config => {
  const token = Vue.ls.get("token")
  if (token) {
    config.headers[ 'X-Access-Token' ] = token
  }
  return config
},(error) => {
  return Promise.reject(error)
})


/**
 * @Description: 响应拦截 （简单处理）
 * @Author: Niklaus
 * @Param:
 * @Return:
 * @Date: 2021-08-24 10:50:59
 */

 axios.interceptors.response.use(
  response => {
    if(response.status === 200)
      return Promise.resolve(response)
    else
      return Promise.reject(response)
  },
  err
)

export {
  httpGet,
  httpPut,
  httpPost,
  httpDelete
}
